import React from 'react'
import HoverCard from '../components/HoverCard/HoverCard'
import HoverCardSection from '../components/HoverCardSection/HoverCardSection'

const RibbonIcon = () => (
  <svg width="29" height="40" viewBox="0 0 29 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.1817 14.1171C28.1817 12.7054 27.3605 11.4106 26.0681 10.9408C26.8893 9.882 27.0083 8.3534 26.3037 7.05858C25.5991 5.76376 24.1901 5.17705 22.8977 5.29394C23.0143 3.99912 22.4287 2.5874 21.1363 1.88155C19.8439 1.17569 18.4348 1.29258 17.2613 2.11757C16.7924 0.824948 15.5 0 14.0909 0C12.6818 0 11.3894 0.822746 10.9204 2.11757C9.74694 1.29482 8.33786 1.17788 7.04544 1.88375C5.75303 2.58963 5.16742 4.00132 5.28409 5.29615C3.99167 5.17924 2.58259 5.76598 1.87805 7.06079C1.1735 8.35561 1.29018 9.76732 2.11363 10.943C0.940142 11.532 0 12.7076 0 14.1193C0 15.5311 0.821215 16.8259 2.11363 17.2957C1.29241 18.3545 1.17349 19.8831 1.87805 21.1779C2.58259 22.4727 3.99167 23.0594 5.28409 22.9425C5.1674 24.2374 5.75305 25.6491 7.04544 26.3549C7.04544 26.3549 7.16213 26.3549 7.16213 26.4718L3.63942 37.7655L8.10228 35.4119L10.4515 39.8831L14.0909 28.2365H14.2076H14.3243L17.9637 40L20.3129 35.5288L24.7757 37.8824L21.1364 26.4718C21.1364 26.4718 21.253 26.4718 21.253 26.3549C22.5455 25.6491 23.1311 24.2374 23.0144 22.9425C24.3068 23.0595 25.7159 22.4727 26.4204 21.1779C27.125 19.8831 27.0083 18.4714 26.1849 17.2957C27.3605 16.8237 28.1817 15.5289 28.1817 14.1171ZM14.0909 22.3536C9.51136 22.3536 5.87197 18.7075 5.87197 14.1194C5.87197 9.53133 9.51136 5.88516 14.0909 5.88516C18.6704 5.88516 22.3098 9.53133 22.3098 14.1194C22.3098 18.7053 18.6704 22.3536 14.0909 22.3536Z"
      fill="#1A1A1A"
    />
  </svg>
)

const ShieldIcon = () => (
  <svg width="35" height="40" viewBox="0 0 35 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.554 21.1431C14.317 20.9061 13.9342 20.9061 13.6973 21.1431C13.4603 21.38 13.4603 21.7628 13.6973 21.9998L14.7694 23.0703C14.8871 23.188 15.0428 23.2476 15.1986 23.2476C15.3544 23.2476 15.5085 23.188 15.6278 23.0703L20.6273 18.0708C20.8643 17.8338 20.8643 17.451 20.6273 17.214C20.3904 16.9771 20.0076 16.9771 19.7706 17.214L15.2004 21.7843L14.554 21.1431Z"
      fill="#1A1A1A"
    />
    <path
      d="M3.63672 7.93258V18.8548C3.63672 26.6568 8.98285 33.6133 16.9371 36.1671C17.0117 36.1903 17.0863 36.2152 17.1609 36.2384C17.2371 36.2152 17.3117 36.1903 17.3862 36.1671C25.3405 33.6135 30.685 26.6568 30.685 18.8565L30.6866 7.93258C23.904 7.10567 19.403 5.24471 17.1609 4.09628C14.9205 5.24466 10.4193 7.10733 3.63672 7.93258ZM24.6759 20.1423C24.6759 24.2852 21.3053 27.6575 17.1607 27.6575C13.0179 27.6575 9.6455 24.2869 9.6455 20.1423C9.6455 15.9978 13.0161 12.6271 17.1607 12.6271C21.3053 12.6271 24.6759 15.9994 24.6759 20.1423Z"
      fill="#1A1A1A"
    />
    <path
      d="M33.7562 4.57693C22.9383 3.81298 17.5625 0.145855 17.5078 0.109372C17.3023 -0.0364572 17.0222 -0.0364572 16.8101 0.109372C16.7621 0.145829 11.388 3.8131 0.563419 4.57693C0.24856 4.60178 0 4.86196 0 5.18345V18.8563C0 28.2441 6.35665 36.5942 15.8242 39.6321C16.2053 39.7531 16.5997 39.8691 16.9991 39.9834C17.0538 39.9934 17.1084 40 17.1631 40C17.2178 40 17.2725 39.9934 17.3206 39.9818C17.7199 39.8674 18.1143 39.7514 18.4955 39.6305C27.9609 36.5946 34.3196 28.2425 34.3196 18.8547V5.18179C34.3196 4.8603 34.0776 4.60013 33.7562 4.57693ZM31.8985 18.8548C31.8985 27.1854 26.216 34.606 17.7581 37.3202C17.6189 37.3649 17.478 37.408 17.3372 37.4511C17.2808 37.4693 17.2212 37.4776 17.1615 37.4776C17.1019 37.4776 17.0422 37.4693 16.9859 37.4511C16.845 37.408 16.7058 37.3649 16.5666 37.3202C8.10834 34.6058 2.42452 27.1849 2.42452 18.8548V7.39381C2.42452 7.08558 2.65652 6.82543 2.96307 6.79225C10.1516 5.9902 14.7668 3.99667 16.8782 2.87971C17.0555 2.78526 17.2676 2.78526 17.4449 2.87971C19.5562 3.99662 24.1711 5.99016 31.3601 6.79225C31.6666 6.82705 31.8986 7.08557 31.8986 7.39381L31.8985 18.8548Z"
      fill="#1A1A1A"
    />
  </svg>
)

const FlagIcon = () => (
  <svg width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.091 0C15.1138 0 11.9961 5.59133 3.89202 7.57813L3.26702 6.09732C3.49607 5.79193 3.63634 5.42437 3.63634 5.01424C3.63634 4.00752 2.82312 3.19075 1.81817 3.19075C0.813222 3.19075 0 4.00752 0 5.01424C0 5.94287 0.694223 6.69568 1.5909 6.80932L15.5253 40L17.2015 39.3022L10.5824 23.5337C16.9728 21.6143 19.6928 17.3369 24.5455 17.7792C27.3172 18.0313 25.9091 23.2496 26.8182 22.7932C31.3636 20.5134 35.9091 19.6025 40.909 19.6025C38.6771 16.8682 36.0386 13.8567 34.5454 10.7285C32.8125 10.8102 31.3033 11.4778 30.2983 11.9821C29.2685 12.4987 28.5121 13.1557 27.4006 13.2072C25.7955 13.4433 24.9556 12.3567 24.0342 11.2417C23.4642 10.4888 22.8286 10.0183 21.9745 10.0165C20.3641 10.2864 18.1536 11.5488 15.9237 12.8805C13.7806 14.1589 11.38 15.7143 9.23325 16.128C9.57949 17.9675 9.53155 19.7057 9.58837 21.1707L4.27567 8.50758C6.78452 10.363 8.09488 12.3818 8.77846 14.3616C10.3854 14.0953 12.7983 12.6269 15.0002 11.313C17.2268 9.98311 19.464 8.57512 21.662 8.20758C23.3524 8.04423 24.4585 8.91958 25.4262 10.0879C25.9411 10.7999 26.25 11.6202 27.2727 11.3982C27.4503 11.3982 28.2724 10.9472 29.4745 10.3436C30.4848 9.83752 32.0579 9.15217 33.8494 8.94798C33.1978 6.90077 33.1303 4.82338 34.0909 2.73712C31.3636 1.82626 28.1819 2.73712 25.0001 4.10429C23.031 2.73712 22.2728 0.00100005 19.091 0.00100005V0Z"
      fill="black"
    />
  </svg>
)

const data = [
  {
    heading: 'ILLUMINATING UNBIASED ACHIEVEMENT',
    description: 'Hospitals do not apply for our selection process, and award winners do not pay to market their honor',
    imageSrc: '/images/temp/unsplash.jpg',
    icon: <RibbonIcon />,
    borderColor: '#FFC532',
    textColor: '#1a1a1a',
  },
  {
    heading: 'A TRANSPARENT ASSESSMENT',
    description: 'We use public data sources and explain the methodologies used to calculate the outcome metric',
    imageSrc: '/images/temp/unsplash.jpg',
    icon: <ShieldIcon />,
    borderColor: '#58C2B7',
    textColor: '#1a1a1a',
  },
  {
    heading: 'MEASURING LEADERSHIP EXCELLENCE',
    description: 'Sheds light on the efficacy of innovative leaders to pinpoint improvement opportunities and adjust key',
    imageSrc: '/images/temp/unsplash.jpg',
    icon: <FlagIcon />,
    borderColor: '#DB5F4F',
    textColor: '#1a1a1a',
  },
]

const Scratchpad = () => {

  return (
    <div>
      <HoverCardSection image="/images/temp/hover-card-section-bg.png">
        {data.map((item, index) => (
          <HoverCard
            key={index}
            heading={item.heading}
            description={item.description}
            icon={item.icon}
            borderColor={item.borderColor}
            textColor={item.textColor}
          />
        ))}
      </HoverCardSection>
    </div>
  )
}

export default Scratchpad
